export type GoogleAnalyticsData = null | {
	clientId: string;
	sessionId: string;
	sessionCount: string;
};

let pollGADataTimeout: NodeJS.Timeout;
let pollCount = 0;
const pollLimit = 1_000;
async function pollGAData(
	measurementID: string,
	cb: (gaData: GoogleAnalyticsData) => void
) {
	pollCount += 1;
	if (typeof document === "undefined") return;
	clearTimeout(pollGADataTimeout);
	if (pollCount >= pollLimit) {
		return cb(null);
	}
	const clientId = await getGTagData(measurementID, "client_id");
	const sessionId = await getGTagData(measurementID, "session_id");
	const sessionCount = await getGTagData(measurementID, "session_number");
	if (!clientId || !sessionId || !sessionCount) {
		pollGADataTimeout = setTimeout(() => {
			pollGAData(measurementID, cb);
		}, 100);
		return;
	} else {
		clearTimeout(pollGADataTimeout);
		cb({ clientId, sessionId, sessionCount });
	}
}

export async function getGTagData(
	measurementID: string,
	field: string
): Promise<string> {
	const _window = window as any;
	if (typeof _window === "undefined" || !_window.gtag) return "";
	const gTag = _window.gtag;
	return new Promise((res) => {
		gTag("get", measurementID, field, (value: any) => {
			res(String(value));
		});
	});
}

export async function getGoogleAnalyticsData(
	measurementID: string
): Promise<GoogleAnalyticsData> {
	if (!measurementID) {
		throw Error(
			`Missing Google "measurementID" to setup useSegmentAnalyticsBrowserWithGA`
		);
	}
	return new Promise(async (res, rej) => {
		await pollGAData(measurementID, (gaSetup) => {
			if (gaSetup) res(gaSetup);
			else rej();
		});
	});
}
