// Necessary if using App Router to ensure this file runs on the client
"use client";

import {
	RumErrorEventDomainContext,
	RumEvent,
	RumFetchResourceEventDomainContext,
	datadogRum,
} from "@datadog/browser-rum";
import {
	APP_BUILD_ENV,
	APP_BUILD_NUMBER,
	DATA_DOG_APPLICATION_ID,
	DATA_DOG_CLIENT_ID,
	DATA_DOG_SERVICE_NAME,
	IS_AUTOMATED_TESTING,
	IS_PRODUCTION_BUILD,
	IS_SSR,
} from "../utilities/env";

const isBot = !IS_SSR && window.navigator.userAgent.includes("bot");

if (
	IS_PRODUCTION_BUILD &&
	!IS_AUTOMATED_TESTING &&
	!isBot &&
	DATA_DOG_APPLICATION_ID &&
	DATA_DOG_CLIENT_ID &&
	DATA_DOG_SERVICE_NAME
) {
	datadogRum.init({
		applicationId: `${DATA_DOG_APPLICATION_ID}`,
		clientToken: `${DATA_DOG_CLIENT_ID}`,
		site: "datadoghq.com",
		service: `${DATA_DOG_SERVICE_NAME}`,
		env: `${APP_BUILD_ENV}`,
		// Specify a version number to identify the deployed version of your application in Datadog
		version: `${APP_BUILD_NUMBER}`,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
		// Specify URLs to propagate trace headers for connection between RUM and backend trace
		allowedTracingUrls: [],
		// Specify which errors to not report
		beforeSend: (event: RumEvent, context) => {
			// will ignore if the message contains the string
			const containsIgnoreMessages = [
				"Failed to load https://www.googletagmanager.com", // ad blocker tag manager
				"Error sending segment performance metrics", // ad blocker segment
				"@ https://cmp.osano.com", // osano
				"Could not clear consent from root domain.", // osano
				`"isTrusted": true`, // unidentified third party
				"@ chrome-extension://", // chrome extension
			];
			// will ignore if the message is exactly the string or if the string is last part of a pre-fetch message
			const exactIgnoreMessages = [
				`Uncaught "Script error."`, // unhelpful
				`Uncaught "Empty reason"`, // unhelpful
				"Load failed", // ad blocker segment
				"Failed to fetch", //ad blocker segment
				"NetworkError when attempting to fetch resource.", // ad blocker segment
			];

			if (event.type === "resource") {
				const c = context as RumFetchResourceEventDomainContext;
				if (c.isAborted) return false;
			}

			if (event.type === "error") {
				const c = context as RumErrorEventDomainContext;
				if (
					containsIgnoreMessages.some(
						(message) =>
							event.error.message.includes(message) ||
							event.error.stack?.includes(message),
					) ||
					exactIgnoreMessages.some(
						(message) => event.error.message === message,
					) ||
					(event.error.message.startsWith("Failed to fetch RSC payload for") &&
						exactIgnoreMessages.some((message) =>
							event.error.message.endsWith(message),
						))
				)
					return false;
			}

			return true;
		},
	});
}

export default function DatadogInit() {
	// Render nothing - this component is only included so that the init code
	// above will run client-side
	return null;
}

export { DatadogInit };
