// Runtime info
export const IS_PRODUCTION_BUILD = process.env.NODE_ENV === "production";
export const IS_SSR = typeof window === "undefined";
export const APP_BUILD_NUMBER =
	process.env.NEXT_PUBLIC_APP_BUILD_NUMBER || "local";
export const APP_BUILD_ENV = process.env.NEXT_PUBLIC_APP_BUILD_ENV || "local";

// Analytics info
export const SEGMENT_WRITE_KEY =
	process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || "";
export const GOOGLE_ANALYTICS_MEASUREMENT_ID =
	process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID || "";
export const LOG_ROCKET_KEY = process.env.NEXT_PUBLIC_LOG_ROCKET_KEY || "";

// Remote sources info
export const HYGRAPH_API_QUA_TOKEN =
	process.env.NEXT_PUBLIC_HYGRAPH_API_QUA_TOKEN || "";
export const LEAD_FORM_API =
	process.env.NEXT_PUBLIC_LEAD_FORM_API ||
	"https://api.claytonhomes.com/graphql";
export const CLAYTON_API =
	process.env.NEXT_PUBLIC_CLAYTON_API || IS_PRODUCTION_BUILD
		? "https://api.claytonhomes.com"
		: "https://api.claytonhomes.itg.cct-pubweb.com";

// Data Dog / Metrics info
export const DATA_DOG_APPLICATION_ID =
	process.env.NEXT_PUBLIC_DATA_DOG_APPLICATION_ID || "";
export const DATA_DOG_CLIENT_ID =
	process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_ID || "";
export const DATA_DOG_SERVICE_NAME =
	process.env.NEXT_PUBLIC_DATA_DOG_SERVICE_NAME || "";
export const DATA_DOG_ENV_NAME =
	process.env.NEXT_PUBLIC_DATA_DOG_ENV_NAME || "";

// e2e testing info
export const E2E_USER_AGENT =
	process.env.E2E_USER_AGENT || "Automated Test Runner";
export const IS_AUTOMATED_TESTING = (() => {
	if (process.env.E2E_USER_AGENT) return true;
	return !IS_SSR && window.navigator.userAgent === E2E_USER_AGENT;
})();

export enum AnalyticEventNames {
	emailLinkClicked = "Email Link Clicked",
	phoneNumberLinkClicked = "Phone Number Link Clicked",
	genericContactFormSubmitted = "Generic Contact Form Submitted",
	productSpecialistContactFormSubmitted = "Product Specialist Contact Submitted",
	digitalModelClicked = "Digital Model Clicked",
	digitalModelListViewed = "Digital Model List Viewed",
	digitalModelConfigurationSubmitted = "Digital Model Configuration Submitted",
	digitalModelConfigurationSaved = "Digital Model Configuration Saved",
	locationUpdated = "Location Updated",
	modelFinderStarted = "Model Finder Started",
	modelFinderUpdated = "Model Finder Updated",
	modelFinderCompleted = "Model Finder Completed",
	ctaClicked = "CTA Clicked",
}

import { env as homeDesignerEnv } from "@clayton-homes/home-designer/util/env";

homeDesignerEnv.HYGRAPH_API_QUA_TOKEN = HYGRAPH_API_QUA_TOKEN;
homeDesignerEnv.CLAYTON_API = CLAYTON_API;

export function setupEnv() {
	homeDesignerEnv.HYGRAPH_API_QUA_TOKEN = HYGRAPH_API_QUA_TOKEN;
	homeDesignerEnv.CLAYTON_API = CLAYTON_API;
}
