import { AnalyticsBrowser } from "@segment/analytics-next";
import { setupSegmentPluginGoogleAnalyticsTransform } from "./segment-ga-transform";
import {
	APP_BUILD_NUMBER,
	GOOGLE_ANALYTICS_MEASUREMENT_ID,
	SEGMENT_WRITE_KEY,
} from "./env";

const analytics =
	typeof window != "undefined" ? new AnalyticsBrowser() : undefined;

analytics?.register(
	setupSegmentPluginGoogleAnalyticsTransform(GOOGLE_ANALYTICS_MEASUREMENT_ID),
);

// We're adding a build number to events so we can track if old events are being fired
analytics?.addSourceMiddleware(({ payload, next }) => {
	const context = payload.obj.context;
	if (context) {
		context.app = {
			...(context.app || {}),
			build: APP_BUILD_NUMBER,
		};
	}
	next(payload);
});
analytics?.load({ writeKey: SEGMENT_WRITE_KEY });

export { analytics };
