import { Plugin } from "@segment/analytics-next";
import {
	getGoogleAnalyticsData,
	GoogleAnalyticsData,
} from "./get-google-analytics-data";

let gaData: GoogleAnalyticsData = null;
export const setupSegmentPluginGoogleAnalyticsTransform = (
	measurementID: string
): Plugin => ({
	name: "GA Transform",
	type: "before",
	version: "1.0.0",
	isLoaded: () => Boolean(gaData),
	load: async (ctx, instance) => {
		instance.queue.criticalTasks.run(() => {
			return getGoogleAnalyticsData(measurementID)
				.then((gd) => {
					gaData = gd;
				})
				.catch((err) => {
					console.warn(`Couldn't load Google Data`);
				});
		});
		return Promise.resolve(ctx);
	},
	track: (ctx) => {
		if (gaData)
			ctx.event.properties = {
				...ctx.event.properties,
				ga: {
					...gaData,
				},
			};
		return ctx;
	},
});
